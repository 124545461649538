<template>
  <div class="form-row" v-if="options.length > 0">
    <sgv-input-select
      placeholder="ค่าใช้จ่าย..."
      class="col-6 col-md-6"
      :options="options"
      select="id"
      v-model="formData.docConfigId"
      @change="updatePrice"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <sgv-input-text
      class="col"
      placeholder="จำนวน ราคา"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-text>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  CREATE_ORDER,
  LIST_ORDER_EXPENSE
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      formType: 'expense',
      formData: {
        docConfigId: null,
        price: ''
      },
      expenses: []
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  apollo: {
    expenses: {
      query () {
        return LIST_ORDER_EXPENSE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contactId
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    options () {
      return this.expenses
    }
  },
  methods: {
    serializeInput (input) {
      try {
        const obj = {docConfigId: input.docConfigId}
        const arr = input.price.split(' ')
        if (arr.length === 1) {
          obj.qty = 1
          obj.price = Number(arr[0])
        } else if (arr.length === 2) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
        }
        return obj
      } catch (err) {
        this.$toasted.global.error('กรอกข้อมูลไม่ถูกต้อง')
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          type: this.formType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    updatePrice () {
      const config = this.expenses.find(v => v.id === this.formData.docConfigId)
      if (config) {
        this.formData.price = `1 ${config.salesPrice || 0}`
      }
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = ''
      this.$v.formData.$reset()
      const config = this.expenses.find(v => v.isDefault)
      if (config) this.formData.docConfigId = config.id
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = ''
          this.$v.formData.$reset()
        } else {
          this.updatePrice()
        }
      }
    }
  },
  created () {
    this.setDefault()
  }
}
</script>

<style lang="css" scoped>
</style>
