<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-radio
          class="col-12"
          inline
          :options="types"
          select="value"
          v-model="selected">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>
    </div>

    <component
      class="col-12"
      :is="selected"
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :formData="formData"
      :contact="contact"
      :url="`/doc/${docType}/${docId}/print`"
      :filename="`${formData.code}.pdf`">
    </component>
  </div>
</template>

<script>
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    contact: {
      type: Object
    }
  },
  data () {
    return {
      types: [
        {text: 'Sample', value: 'DetailPrintPdf'}
      ],
       selected: 'DetailPrintPdf'
    }
  },
  components: {
    DetailPrintPdf
  }
}
</script>

<style lang="css" scoped>
</style>
