<template>
  <sgv-input-select
    :options="contacts"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="id">
    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_LOGISTIC } from './graph'

export default {
  name: 'SelectLogisticId',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    contact: {
      type: Object,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      contacts: []
    }
  },
  apollo: {
    contacts: {
      query () {
        return LIST_LOGISTIC(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contact.id,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        if (!this.contact) {
          this.emitInput(null)
          return true
        }
      },
      result (res) {
        const found = res.data.contacts.find(v => v.isDefault)
        if (found) this.emitInput(found.id)
        else this.emitInput(null)
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
