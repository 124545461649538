<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <DetailFormSalesCreditInput
        label="เครดิตวัน"
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-2"
        select="salesCredit"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contact="contact"
        v-model="formData.salesCredit">
        <template slot-scope="option">
          {{option.salesCredit}}
        </template>
      </DetailFormSalesCreditInput>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <DetailFormContactInput
        label="ลูกหนี้"
        :docType="docType"
        :templateType="templateType"
        placeholder="..."
        :disabled="method !== 'add'"
        class="col-12"
        select="id"
        @change="setContact"
        v-model="formData.contactId"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
      </DetailFormContactInput>
    </div>

    <div class="form-row" v-if="contact && contact.isUnknown">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ชื่อที่อยู่ลูกค้า"
        auto-grow
        :rows="3"
        v-model="formData.name">
      </sgv-input-textarea>
    </div>

    <div class="form-row" v-if="isRepair">
      <DetailFormRepairInput
        :docType="docType"
        :templateType="templateType"
        label="ใบสินค้าชำรุด / แจ้งซ่อม"
        class="col-12"
        select="id"
        :docId="docId"
        :method="method"
        :formData="formData">
      </DetailFormRepairInput>
    </div>

    <div class="form-row">
      <DetailFormSalesInput
        label="พนักงานขาย"
        placeholder="..."
        :docType="docType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-6"
        select="id"
        :docId="docId"
        :contact="contact"
        v-model="formData.salesId">
      </DetailFormSalesInput>

      <sgv-input-datetime
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-6"
        v-model="formData.deliveredAt"
        label="เวลาจัดส่ง">
      </sgv-input-datetime>

      <DetailFormLogisticInput
        label="ขนส่ง"
        placeholder="..."
        :docType="docType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        select="id"
        :docId="docId"
        :contact="contact"
        v-model="formData.logisticId">
      </DetailFormLogisticInput>
    </div>

    <div class="form-row">
      <DetailFormDocRemarkInput
        :docType="docType"
        :templateType="templateType"
        :disabled="$auth.disabled(method)"
        class="col-12"
        v-model="formData.docRemark"
        :method="method">
      </DetailFormDocRemarkInput>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormRepairInput from '@/views/doc_core/components/DetailFormRepairInput.vue'
import DetailFormContactInput from './DetailFormContactInput'
import DetailFormSalesCreditInput from './DetailFormSalesCreditInput'
import DetailFormLogisticInput from './DetailFormLogisticInput'
import DetailFormSalesInput from './DetailFormSalesInput'
import DetailFormDocRemarkInput from './DetailFormDocRemarkInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    isRepair: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      contact: null
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    setContact (value) {
      this.contact = value
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    contact (v) {
      this.$emit('contact', v)
    }
  },
  components: {
    DetailFormContactInput,
    DetailFormSalesCreditInput,
    DetailFormLogisticInput,
    DetailFormSalesInput,
    DetailFormDocRemarkInput,
    CategoryCheck,
    DetailFormRepairInput
  }
}
</script>

<style lang="css">
</style>
