<template>
  <tr
    v-if="!row.parentId || selectedRow === row.parentId"
    class="pointer"
    @dblclick.stop="emitInput(row)">
    <td>
      <fa
        v-if="canDestroy && (selectedRow === row.id || selectedRow === row.parentId) && !['vatPrice', 'subVatPrice', 'netPrice', 'subNetPrice'].includes(row.type)"
        icon="trash"
        class="text-danger pointer"
        @dblclick="destroyOrder(row.id, row.parentId)">
      </fa>
      <span v-if="!row.parentId">
        {{ row.id }}
      </span>
    </td>
    <td>
      <span style="white-space: pre-line;">{{ row.name }}</span>
    </td>
    <td class="text-nowrap">
      <span v-if="['vatPrice', 'netPrice'].includes(row.type)">
        -
      </span>
      <span v-else>
        <span :class="{'text-success': row.qty > 0, 'text-danger': row.qty < 0}">
          {{ Number(row.qty) | comma }}
        </span>
        <span v-if="row.unit">{{ row.unit.name }}</span>
      </span>
    </td>
    <td>
      <span v-if="row.price > 0">{{ row.price | comma  }}</span>
      <span v-else>-</span>
      <small v-if="row.discount" class="text-primary">
        <br>{{row.discount}} ({{row.discountPrice}})
      </small>
    </td>
    <td>
      {{ row.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import { DESTROY_ORDER } from './graph'

export default {
  name: 'SubOrderItem',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      required: false
    },
    canDestroy: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    selectedRow () {
      return this.value.find(v => v === this.row.id || v === this.row.parentId)
    }
  },
  methods: {
    emitInput (row) {
      if (row.parentId) return
      const found = this.value.indexOf(row.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(row.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
