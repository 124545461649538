<template>
  <sgv-input-textarea
    :disabled="disabled"
    label="หมายเหตุเอกสาร:"
    :rows="1"
    auto-grow
    :value="value"
    @input="$emit('input', $event)">

    <div slot="label">
      <div
        v-if="['add', 'edit'].includes(method)"
        class="ml-2 mb-2">
        <div
          v-for="(item, i) in items"
          :key="item.text">
          <span
            class="pointer"
            @click="setRemark(item.value)">
            {{i+1}}. {{item.text}}
          </span>
        </div>
      </div>
    </div>
  </sgv-input-textarea>
</template>

<script>
import { LIST_DOC_REMARK } from './graph'

export default {
  name: 'DetailFormDocRemarkInput',
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      remarks: []
    }
  },
  apollo: {
    remarks: {
      query () {
        return LIST_DOC_REMARK(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      }
    }
  },
  computed: {
    items () {
      return this.remarks.map(v => {
        return {
          text: v.name,
          value: v.docRemark
        }
      })
    }
  },
  methods: {
    setRemark (txt) {
      if (this.disabled) return
      this.$emit('input', txt)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
