<template>
  <sgv-input-select
    :options="docConfigs"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="salesCredit">
    <template slot-scope="option">
      {{option.salesCredit}}
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_SALES_CREDIT } from './graph'

export default {
  name: 'SelectSalesCredit',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    contact: {
      type: Object,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      docConfigs: []
    }
  },
  apollo: {
    docConfigs: {
      query () {
        return LIST_SALES_CREDIT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contact.id,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        if (!this.contact) {
          this.emitInput(0)
          return true
        }
      },
      result (res) {
        const found = res.data.docConfigs.find(v => v.isDefault)
        if (found) this.emitInput(found.salesCredit)
        else this.emitInput(0)
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
