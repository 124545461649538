<template>
  <sgv-input-select
    :options="contacts"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="id">
    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-select>
</template>

<script>
import { 
  LIST_SALES,
  LIST_SALES_LOCATION
} from './graph'

export default {
  name: 'SelectSalesId',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    contact: {
      type: Object,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      contacts: []
    }
  },
  apollo: {
    contacts: {
      query () {
        return LIST_SALES(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.contact ? this.contact.id : null,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache',
      result (res) {
        if (!this.contact?.id) {
          const found = res.data.contacts.find(v => v.isDefault)
          if (found) this.emitInput(found.id)
          else this.emitInput(null)
        } else if (this.docId === 0) {
          this.fetchSalesLocation(this.contact.id)
          .then(resp => {
            const salesAvailableIds = res.data.contacts.map(c => c.id)
            const contacts = resp.data.contacts.filter(c => salesAvailableIds.includes(c.id))
            if (contacts.length > 0) {

              if (contacts.length === 1) {
                this.emitInput(contacts[0].id)
                return
              }
              
              const found = contacts.find(v => v.isDefault)

              if (found) {
                this.emitInput(found.id)
                return
              }

              this.emitInput(null)
            }
          })
          .catch(() => {})
        }
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    fetchSalesLocation (contactId) {
      return this.$apollo.query({
        query: LIST_SALES_LOCATION(this.templateType),
        variables: {
          docType: this.docType,
          contactId
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
