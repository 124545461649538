<template>
  <sgv-table
    :items="orders"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderInventoryInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderInventoryInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderExpenseInput>

      <DetailOrderDiscountInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :orders="orders">
      </DetailOrderDiscountInput>
    </template>

    <template slot-scope="row">
      <DetailOrderSubOrderItem
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="row.item"
        v-model="selectedRows"
        :selected.sync="selectedOrder"
        :canDestroy="!formData.approvedAt">
      </DetailOrderSubOrderItem>

      <DetailOrderSubOrderItem
        v-for="child in row.item.children"
        :key="child.id"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :row="child"
        :value="selectedRows"
        :canDestroy="!formData.approvedAt">
      </DetailOrderSubOrderItem>
    </template>
  </sgv-table>
</template>

<script>
import DetailOrderSubOrderItem from './DetailOrderSubOrderItem.vue'
import DetailOrderInventoryInput from './DetailOrderInventoryInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderDiscountInput from './DetailOrderDiscountInput.vue'
import {
  LIST_ORDER,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
      selectedOrder: null
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      update (data) {
        return [
          ...data.orders.filter(v => v.type === 'item'),
          ...data.orders.filter(v => v.type === 'service'),
          ...data.orders.filter(v => v.type === 'expense'),
          ...data.orders.filter(v => v.type === 'discountPrice'),
          ...data.orders.filter(v => v.type === 'vatPrice'),
          ...data.orders.filter(v => v.type === 'netPrice')
        ]
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const orderIndex = previous.orders.findIndex(v => v.id === subscriptionData.data.orderUpdated.id)
            let newOrders

            if (orderIndex !== -1) {
              newOrders = [...previous.orders]
              newOrders[orderIndex] = subscriptionData.data.orderUpdated
            } else {
              newOrders = [
                ...previous.orders,
                subscriptionData.data.orderUpdated
              ]
            }

            return {
              ...previous,
              orders: newOrders
            }
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const {id, parentId} = subscriptionData.data.orderDestroyed
            let newOrders = []
            if (parentId) {
              const parentIndex = previous.orders.findIndex(v => v.id === parentId)
              if (parentIndex === -1) return
              const orderIndex = previous.orders[parentIndex].children.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders[parentIndex].children.splice(orderIndex, 1)
            } else {
              const orderIndex = previous.orders.findIndex(v => v.id === id)
              if (orderIndex === -1) return
              newOrders = [...previous.orders]
              newOrders.splice(orderIndex, 1)
            }
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
      ]
    },
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      let arr = []
      if (!this.formData.closedAt && this.docId > 0) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    }
  },
  watch: {
    orders () {
      this.selectedOrder = null
    },
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  components: {
    DetailOrderSubOrderItem,
    DetailOrderInventoryInput,
    DetailOrderExpenseInput,
    DetailOrderDiscountInput
  }
}
</script>

<style lang="css">
</style>
