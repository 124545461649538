<template>
  <div class="form-row">
    <DetailFormContactInput
      label="ลูกหนี้ (ใบสั่งขาย)"
      placeholder="..."
      class="col-12"
      select="id"
      v-model="formData.contactId"
      :docType="docType"
      :templateType="templateType"
      :validations="[
        {text: 'required!', value: $v.formData.contactId.$dirty && $v.formData.contactId.$error}
      ]">
      <template slot-scope="option">
        {{option.contact.code}} ({{option.contact.name}})
      </template>
    </DetailFormContactInput>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CREATE_SALESORDER } from './graph'
import DetailFormContactInput from './DetailFormContactInput'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      formData: {
        contactId: this.contactId
      }
    }
  },
  validations: {
    formData: {
      contactId: {required}
    }
  },
  methods: {
    createData () {
      this.$apollo.mutate({
        mutation: CREATE_SALESORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          contactId: this.formData.contactId
        }
      })
      .then(res => {
        this.$router.push({
          name: `Doc${this.$form.capitalize(res.data.createDoc.type)}Detail`,
          params: {docId: res.data.createDoc.id},
        })
      })
      .catch(this.$toasted.global.error)
    },
  },
  components: {
    DetailFormContactInput
  }
}
</script>

<style lang="css" scoped>
</style>
