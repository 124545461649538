<template>
  <div class="row">
    <div class="col-12">
      <DetailDeliveryLocationCard
        class="mb-3"
        v-for="addr in addresses"
        :key="addr.id"
        :item="addr">
      </DetailDeliveryLocationCard>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailDeliveryLocationCard from './DetailDeliveryLocationCard'
import { LIST_ADDRESS_DELIVERY_LOCATION } from './graph/address'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailDeliveryLocation`,
      addresses: []
    }
  },
  apollo: {
    addresses: {
      query () {
        return LIST_ADDRESS_DELIVERY_LOCATION(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  components: {
    DetailDeliveryLocationCard
  }
}
</script>

<style lang="css">
</style>
