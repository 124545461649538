<template>
  <div class="form-row">
    <DetailOrderInventoryInputInventory
      ref="dropdownInventory"
      placeholder="ค้นหา..."
      class="col-12"
      select="id"
      :contactId="contactId"
      :docType="docType"
      :templateType="templateType"
      v-model="inventoryDocConfigId"
      @keyup.enter.native="$refs.qty.setFocus()"
      @change="inventory = $event"
      @group="setGroup"
      :validations="[
        {text: 'required!', value: $v.inventoryDocConfigId.$dirty && $v.inventoryDocConfigId.$error}
      ]">
    </DetailOrderInventoryInputInventory>

    <template v-if="selectedGroup">
      <DetailOrderInventoryStockBalance
        class="col"
        label="ราคา"
        :inventoryDocConfigId="inventoryDocConfigId"
        :inventoryId="inventoryId"
        :docType="docType"
        :templateType="templateType">
      </DetailOrderInventoryStockBalance>

      <sgv-input-number
        ref="qty"
        class="col-6 col-sm-3"
        placeholder="จำนวน"
        :precision="2"
        label="จำนวน"
        v-model="formData.qty"
        :validations="[
          {text: 'required!', value: $v.formData.qty.$dirty && !$v.formData.qty.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.qty.$dirty && !$v.formData.qty.minValue}
        ]"
        @keyup.enter.native="$refs.submit.focus()">
        <span slot="label" class="ml-2" v-if="inventory">
          <span
            v-for="child in converters"
            :key="child.id"
            class="pointer"
            @click="convertQty(child)">
            {{child.name}}
          </span>
        </span>
      </sgv-input-number>

      <DetailOrderInventoryInputPrice
        label="ราคา"
        :type="selectedGroup"
        class="col-6 col-sm-3"
        :contactId="contactId"
        :inventoryDocConfigId="inventoryDocConfigId"
        :qty="formData.qty"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.docConfigId"
        :validations="[
          {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
        ]">
      </DetailOrderInventoryInputPrice>

      <div class="col-auto align-self-end mb-3">
        <button
          ref="submit"
          class="btn btn-success"
          @click="createOrder">
          <fa icon="plus"></fa>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderInventoryInputInventory from './DetailOrderInventoryInputInventory.vue'
import DetailOrderInventoryInputPrice from './DetailOrderInventoryInputPrice.vue'
import DetailOrderInventoryStockBalance from './DetailOrderInventoryStockBalance.vue'
import { CREATE_ORDER } from './graph'
import round from 'lodash/round'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      formData: {
        docConfigId: null,
        qty: 0,
      },
      inventoryDocConfigId: null,
      inventory: null,
      selectedGroup: null
    }
  },
  validations: {
    inventoryDocConfigId: {required},
    formData: {
      docConfigId: {required},
      qty: {
        required,
        minValue: (value) => value > 0
      },
    }
  },
  computed: {
    inventoryId () {
      return this.inventory?.inventory.id
    },
    converters () {
      if (!this.inventory) return []

      return this.inventory.children.filter(v => v.type === 'converter')
    }
  },
  methods: {
    convertQty (child) {
      const prefix = child.converter.charAt(0)
      let qty = Math.abs(+child.converter * this.formData.qty)
      if (prefix === '+') {
        qty = Math.ceil(qty * 100) / 100
      } else if (prefix === '-') {
        qty = Math.floor(qty * 100) / 100
      }
      this.formData.qty = round(Math.abs(qty), 2)
    },
    createOrder () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          type: this.selectedGroup,
          docId: this.docId,
          input: this.formData
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.inventoryDocConfigId = null
      this.formData.docConfigId = null
      this.formData.qty = 0
      this.$v.$reset()
    },
    setGroup (group) {
      this.selectedGroup = group
      this.setDefault()
    }
  },
  watch: {
    'inventoryDocConfigId': {
      handler (v) {
        if (!v) {
          this.formData.docConfigId = null
          this.formData.qty = 0
          this.$v.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderInventoryInputInventory,
    DetailOrderInventoryInputPrice,
    DetailOrderInventoryStockBalance
  }
}
</script>

<style lang="css" scoped>
</style>
