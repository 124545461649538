var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row"},[_c('DetailOrderInventoryInputInventory',{ref:"dropdownInventory",staticClass:"col-12",attrs:{"placeholder":"ค้นหา...","select":"id","contactId":_vm.contactId,"docType":_vm.docType,"templateType":_vm.templateType,"validations":[
      {text: 'required!', value: _vm.$v.inventoryDocConfigId.$dirty && _vm.$v.inventoryDocConfigId.$error}
    ]},on:{"change":function($event){_vm.inventory = $event},"group":_vm.setGroup},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.qty.setFocus()}},model:{value:(_vm.inventoryDocConfigId),callback:function ($$v) {_vm.inventoryDocConfigId=$$v},expression:"inventoryDocConfigId"}}),(_vm.selectedGroup)?[_c('DetailOrderInventoryStockBalance',{staticClass:"col",attrs:{"label":"ราคา","inventoryDocConfigId":_vm.inventoryDocConfigId,"inventoryId":_vm.inventoryId,"docType":_vm.docType,"templateType":_vm.templateType}}),_c('sgv-input-number',{ref:"qty",staticClass:"col-6 col-sm-3",attrs:{"placeholder":"จำนวน","precision":2,"label":"จำนวน","validations":[
        {text: 'required!', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.required},
        {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.minValue}
      ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.submit.focus()}},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}},[(_vm.inventory)?_c('span',{staticClass:"ml-2",attrs:{"slot":"label"},slot:"label"},_vm._l((_vm.converters),function(child){return _c('span',{key:child.id,staticClass:"pointer",on:{"click":function($event){return _vm.convertQty(child)}}},[_vm._v(" "+_vm._s(child.name)+" ")])}),0):_vm._e()]),_c('DetailOrderInventoryInputPrice',{staticClass:"col-6 col-sm-3",attrs:{"label":"ราคา","type":_vm.selectedGroup,"contactId":_vm.contactId,"inventoryDocConfigId":_vm.inventoryDocConfigId,"qty":_vm.formData.qty,"docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.docConfigId.$dirty && _vm.$v.formData.docConfigId.$error}
      ]},model:{value:(_vm.formData.docConfigId),callback:function ($$v) {_vm.$set(_vm.formData, "docConfigId", $$v)},expression:"formData.docConfigId"}}),_c('div',{staticClass:"col-auto align-self-end mb-3"},[_c('button',{ref:"submit",staticClass:"btn btn-success",on:{"click":_vm.createOrder}},[_c('fa',{attrs:{"icon":"plus"}})],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }